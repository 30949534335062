import { ReactNode, useState } from "react";
import { Sidebar } from "../Sidebar";
import { MenuArr } from "../Sidebar/constant";
import RTC_LOGO from "../../images/rtc-logo.svg";
import { ComponentWrapper } from "../ComponentWrapper";

interface IComponentWrapper {
  children: ReactNode;
}

export const Layout: React.FC<IComponentWrapper> = ({ children }) => {
  const [open, setOpen] = useState(true);
  return (
    <ComponentWrapper
      sidebar={
        <Sidebar
          toggle={open}
          setToggle={setOpen}
          logoUrl={RTC_LOGO}
          menuArr={MenuArr}
        />
      }
    >
      {children}
    </ComponentWrapper>
  );
};
